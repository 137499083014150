/* Eat Protein Dashboard CSS starts  */
.dashboard-nav {
    background-color: blue;
    color: #ffffff;
  }
  
  .dashboard-nav:hover {
    color: #ffffff;
  }
  
  .dashboard-input {
    height: 40px;
    border-radius: 5px;
    background-color: #d1d0d0;
  
    width: 100%;
  }
  
  ::placeholder {
    color: #c4c4c4;
  }
  
  .dashboard-input-container {
    padding:10px 0px;
  }
  
  .total-product-para {
    background-color:#3874ff;
    color: #ffffff;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
  }
  
  .padding-total {
    padding-left: 70px;
  }
  
  /* table,
  th,
  td,
  tr {
    border: 1px solid black;
    border-collapse: collapse;
  }
  
  #table #Row1 th {
    text-align: center;
    background-color: #5db934;
    color:#ffffff;
  }
  
  #table #Row2 th {
    background-color: #8bca84;
  } */
  
  /* table tr#Row1{
    text-align:center;
    background-color:;
  } */
  
  /* Eat Protein Dashboard CSS ends  */
  
  /* Eat Protein Product Add CSS starts */
  .calories-width {
    width: 15%;
    padding: 10px;
    margin-right: 20px;
  }
  
  .calories-para-width {
    width: 10%;
  }
  
  .calories-span {
    margin-right: 30px;
  }
  
  .login-input-outline {
    border: 0.5px 0.5px 0.5px 0.5px;
    border-color: #dee2e6;
  
    border-radius: 5px;
    width: 10%;
    height: 35px;
  }
  
  
  .summary-para {
    border: 01px solid yellow;
    width: 55%;
    border-radius: 5px;
    padding: 10px;
  }
  
  .summary-span {
    font-weight: 700;
  }
  
  .button-margin {
    margin-left: auto;
  }
  
  .inline-button-margin {
    margin-right: 15px;
  }
  
  /* Eat Protein Product Add CSS starts */