.price-box{
    min-width: 100px;
    float: left;
    border: 1px solid #c4c4c4;
    padding: 5px;
    border-radius: 10px;
    margin-right: 10px;
    font-size: .8rem;
    background: #fff;
}

.float-edit-btn{
    position: absolute;
    right: -5px;
    top: -5px;
    width: 25px;
    height: 25px;
    background: #b96bdb;
    color: #fff;
    padding: 1px 3px 3px 5px;
    text-align: center;
    border-radius: 50%;
    display: none;
    cursor: pointer;
}

.price-box:hover .float-edit-btn{
    display: block;
}

.add-btn-box{
    float: left;
    border: 1px solid #c4c4c4;
    padding: 10px 10px;
    border-radius: 10px;
    margin-right: 10px;
    font-size: .8rem;
    background: #fff;
}
.price-add-btn{
    /* right: -5px; */
    /* top: -5px; */
    width: 25px;
    height: 25px;
    background: #8a8a8a;
    color: #fff;
    padding: 0px 5px 4px 5px;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
}